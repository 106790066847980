<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增商品</el-button>
          <el-input size="medium" v-model="searchData.code" placeholder="输入商品编码" class="marginLeft10 width200"/>
          <el-input size="medium" v-model="searchData.name" placeholder="输入商品名称" class="marginLeft10 width200"/>
          <el-input size="medium" v-model="searchData.supplierName" placeholder="输入供应商名称" class="marginLeft10 width200"/>
          <GoodsCascader :cascaderValue="cascaderValue" ref="cascader" class="marginLeft10 width250" @change="handleCascader"/>
          <div class="marginLeft10 width200">
            <el-select v-model="searchData.state" size="medium" clearable>
              <el-option label="上架状态" value=""></el-option>
              <el-option label="已上架" :value="1"></el-option>
              <el-option label="未上架" :value="2"></el-option>
            </el-select>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
        </div>
      </template>
      <template slot="isRecommend" slot-scope="{ row }">
        <el-switch v-model="row.isRecommend" :inactive-value="2" :active-value="1" @change="handleRecommend($event,row.id)"></el-switch>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch v-model="row.state" :inactive-value="2" :active-value="1" @change="handleState($event,row.id)"></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import {list, updateRecommend, updateState, deleteNew} from "@/api/shop/goods";
import GoodsCascader from "@/components/goods-cascader";
export default {
  name: "index",
  components: {
    GoodsCascader
  },
  data(){
    return{
      cascaderValue: [],
      searchData: {
        code: '',
        name: '',
        supplierName: '',
        classifyOneId: '',
        classifyTwoId: '',
        classifyThreeId: '',
        state: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'商品管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '商品编码',
            prop: 'code'
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '供应商名称',
            prop: 'supplierName'
          },
          {
            label: '商品分类',
            prop: 'classifyOneName',
            formatter: function(row, column, cellValue, index){
              return `${row.classifyOneName}/${row.classifyTwoName}/${row.classifyThreeName}`
            }
          },
          {
            label: '排序值',
            prop: 'sort'
          },
          {
            label: '最小商品库存',
            prop: 'stock'
          },
          {
            label: '虚拟销量',
            prop: 'virtualSales'
          },
          {
            label: '是否推荐',
            prop: 'isRecommend'
          },
          {
            label: '上架状态',
            prop: 'state'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ]
      },
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    /* 新增商品 */
    handleCreate(){
      console.log('新增商品');
      this.$router.push({
        path: '/shop/goods/create'
      })
    },
    /* 查询商品 */
    handleSearch(){
      console.log('查询商品');
      this.page.currentPage = 1;
      this.onLoad();
    },
    /* 分类ID获取 */
    handleCascader(arr){
      // console.log(arr)
      this.cascaderValue = arr;
      if(arr.length > 0){
        this.searchData.classifyOneId = arr[0];
        this.searchData.classifyTwoId = arr[1] || '';
        this.searchData.classifyThreeId = arr[2] || '';
      }else{
        this.searchData.classifyOneId = '';
        this.searchData.classifyTwoId = '';
        this.searchData.classifyThreeId = '';
      }
    },
    /* 重置查询 */
    handleReset(){
      console.log('重置查询');
      this.searchData = {
        code: '',
        name: '',
        supplierName: '',
        classifyOneId: '',
        classifyTwoId: '',
        classifyThreeId: '',
        state: ''
      };
      this.cascaderValue = [];
      this.$refs.cascader.setValue();
      this.onLoad();
    },
    /* 编辑商品 */
    handleEdit(row){
      console.log('编辑商品',row);
      this.$router.push({
        path: '/shop/goods/create',
        query: {
          id: row.id
        }
      })
    },
    /* 删除商品 */
    handleDelete(row){
      console.log('删除商品',row);
      this.$confirm('是否删除此商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({id: row.id}).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    /* 推荐商品 */
    handleRecommend(e,id){
      // console.log('推荐商品',e,id);
      updateRecommend({
        id,
        isRecommend: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.warning(res.msg);
        }
        this.onLoad();
      })
    },
    /* 上架商品 */
    handleState(e,id){
      console.log('上架商品',e,id);
      updateState({
        id,
        state: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.warning(res.msg);
        }
        this.onLoad();
      })
    }
  }
}
</script>

<style scoped>

</style>