<template>
  <div>
    <el-cascader
        v-model="value"
        :options="options"
        :props="{ label: 'name', value: 'id', children: 'children' }"
        clearable
        placeholder="商品类型"
        @change="handleChange"></el-cascader>
  </div>
</template>

<script>
import {listClassify} from "@/api/shop/goods";
export default {
  name: "index",
  props: {
    cascaderValue: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      value: this.cascaderValue,
      options: []
    }
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    getOptions() {
      listClassify().then(res => {
        console.log(res);
        this.options = res.data;
      })
    },
    handleChange(value) {
      this.$emit('change', value);
    },
    setValue(){
      this.value = [];
    }
  }
}
</script>

<style scoped>

</style>